<template>
  <ion-list v-if="resources != null">
    <ion-list-header>
      <p>{{ title }}</p>
    </ion-list-header>

    <ion-item-sliding
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item
        v-show="eventVisibility(item.event_from_date, item.event_to_date)"
      >
        <ion-label>
          <ion-text :color="colorName(item.event_type.color)">
            <h2>{{ item.name }}</h2>
          </ion-text>
          <p>{{ parseDate(item.event_from_date, item.event_to_date) }}</p>
          <h3 class="ion-text-wrap">{{ item.description }}</h3>
        </ion-label>
        <!-- <ion-buttons slot="end">
          <ion-button @click="slide(item.id)">
            <ion-icon
              slot="icon-only"
              :ios="ellipsisHorizontalOutline"
              :md="ellipsisHorizontalSharp"
              size="small"
            ></ion-icon>
          </ion-button>
        </ion-buttons> -->
      </ion-item>
      <!-- <ion-item-options side="start">
        <ion-item-option
          color="light"
          @click="setResourceToUpdate(item.id)"
        >
          <ion-icon
            slot="icon-only"
            :ios="createOutline"
            :md="createSharp"
            size="small"
          ></ion-icon>
        </ion-item-option>
        <ion-item-option
          color="light"
          @click="confirmDelete({ id: item.id, name: item.name })"
        >
          <ion-icon
            slot="icon-only"
            :ios="trashOutline"
            :md="trashSharp"
            size="small"
            style="color: #eb445a"
          ></ion-icon>
        </ion-item-option>
      </ion-item-options> -->
    </ion-item-sliding>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No events found</p>
    </ion-list-header>
  </ion-list>
  <!-- <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal title="Update chargeable" @closeModal="setUpdateModalState(false)">
      <update-event
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-event>
    </base-modal>
  </ion-modal> -->
</template>
<script>
// import BaseModal from "../../Base/BaseModal.vue";
// import UpdateEvent from "../Calendar/UpdateEvent.vue";

import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItemSliding,
  // IonItemOptions,
  // IonItemOption,
  IonItem,
  IonText,
  //   IonNote,
  // IonButtons,
  // IonButton,
  // IonIcon,
  //   IonModal,
  // alertController,
} from "@ionic/vue";

// import {
//   trashOutline,
//   trashSharp,
//   createOutline,
//   createSharp,
//   ellipsisHorizontalOutline,
//   ellipsisHorizontalSharp,
// } from "ionicons/icons";

import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    filter_date: {
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItemSliding,
    // IonItemOptions,
    // IonItemOption,
    IonItem,
    IonText,
    // IonNote,
    // IonButtons,
    // IonButton,
    // IonIcon,
    // IonModal,
    // BaseModal,
    // UpdateEvent,
  },
  data() {
    return {
        // trashOutline,
        // trashSharp,
        // createOutline,
        // createSharp,
        // ellipsisHorizontalOutline,
        // ellipsisHorizontalSharp,
        // updateModalState: false,
        // resourceToUpdate: {
        //   id: null,
        //   name: null,
        // },
        // xhrError: {
        //   header: null,
        //   description: null,
        // },
    };
  },

  computed: {
    title() {
      if (!this.filter_date) {
        return "All events";
      }

      return `Events for ${moment(this.filter_date).format("ll")}`;
    },
  },

  methods: {
    // slide(id) {
    //   this.$refs[`items-${id}`].$el.open();
    // },

    // alerter(header, message, buttons = ["close"]) {
    //   const alert = alertController.create({
    //     header: header,
    //     message: message,
    //     buttons: buttons,
    //   });

    //   return alert;
    // },

    // setErrorResponse(error) {
    //   if (error.response) {
    //     this.xhrError.header = error.response.data.header;
    //     this.xhrError.message = error.response.data.message;
    //   } else if (error.request) {
    //     console.log(error.request);
    //     this.xhrError.header = error.message || error.request;
    //     this.xhrError.message = "Please check your connection and try again";
    //   } else {
    //     this.xhrError.header = error.message;
    //     this.xhrError.message = "Something went wrong. Try again later";
    //   }
    // },

    // async confirmDelete(resource) {
    //   const header = "Delete event ?";
    //   const message = `Event "${resource.name}" will be deleted.`;
    //   const buttons = [
    //     {
    //       text: "Cancel",
    //       role: "cancel",
    //       cssClass: "secondary",
    //     },
    //     {
    //       text: "Okay",
    //       handler: () => {
    //         this.delete(resource.id);
    //       },
    //     },
    //   ];
    //   const alertDialog = await this.alerter(header, message, buttons);

    //   alertDialog.present();
    // },

    // async delete(data) {
    //   try {
    //     await this.$store.dispatch("event/deleteResource", data);
    //   } catch (error) {
    //     this.setErrorResponse(error);
    //     const alertDialog = await this.alerter(
    //       this.xhrError.header,
    //       this.xhrError.message
    //     );
    //     alertDialog.present();
    //   }
    // },

    // setUpdateModalState(state) {
    //   this.updateModalState = state;
    // },

    // setResourceToUpdate(id) {
    //   const resource = this.resources.data.find(
    //     (resource) => resource.id === id
    //   );
    //   this.resourceToUpdate = resource;
    //   this.setUpdateModalState(true)
    // },

    parseDate(from, to) {
      const from_str = moment(from).format("ll");
      if (from === to) {
        return from_str;
      }
      const to_str = moment(to).format("ll");
      return `${from_str} to ${to_str}`;
    },

    colorName(name) {
      let color = null;
      switch (name) {
        case "yellow":
          color = "warning";
          break;
        case "red":
          color = "danger";
          break;
        case "green":
          color = "success";
          break;
        default:
          color = "primary";
      }

      return color;
    },

    eventVisibility(from, to) {
      if (!this.filter_date) {
        return true;
      }
      const isBetween = moment(this.filter_date).isBetween(
        from,
        to,
        undefined,
        "[]"
      );
      return isBetween;
    },
  },
};
</script>
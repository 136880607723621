<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <v-calendar
            is-expanded
            trim-weeks
            @dayclick="onDayClick"
            @update:to-page="onPageUpdate"
            :attributes="attributes"
          >
          </v-calendar>
        </ion-col>
        <ion-col size="12">
          <list-event :resources="resources" :filter_date="filter_date"></list-event>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import ListEvent from "../../Components/Calendar/ListEvent.vue";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import { addOutline } from "ionicons/icons";

import moment from "moment";

export default {
  components: {
    BaseLayout,
    ListEvent,
    IonGrid,
    IonRow,
    IonCol,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      title: "Carendar & Events",
      backLink: "dashboard",
      reloadable: true,

      filter_date: null,

      startOfMonth: moment().startOf("month").format("YYYY-MM-DD"),

      createModalState: false,

      addOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["event/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },

    attributes() {
      const events = this.resources.data;

      return [
        ...events.map((event) => ({
          key: event.id,
          dates: {
            start: new Date(event.event_from_date),
            end: new Date(event.event_to_date),
          },
          dot: event.event_type.color,
          popover: {
            label: event.name,
          },
        })),
      ];
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = false) {
      await this.$store.dispatch("event/resources", {
        refresh: refresh,
        startOfMonth: this.startOfMonth,
      });
    },
    loadData(event) {
      this.fetch();
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("event/resources", {
        refresh: true,
        startOfMonth: this.startOfMonth,
      });
      if (event) {
        event.target.complete();
      }
    },
    setCreateModalState(state) {
      this.createModalState = state;
    },
    onDayClick(day) {
      this.filter_date = day.id
    },

    onPageUpdate(page) {
      this.filter_date = null
      const firstDayOfMonth = moment([page.year, page.month - 1, 1]).format(
        "YYYY-MM-DD"
      );
      this.startOfMonth = firstDayOfMonth;
      this.refresh();
    },
  },

  created() {
  },
};
</script>

<style scoped>
</style>
